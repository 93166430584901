import React from "react"
import Canvas from "../components/canvas"

export default function Home() {
  return (
    <div>
      <Canvas/>
    </div>
  );
}
