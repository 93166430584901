import React from "react"
import Particles from "react-particles-js"
import styles from "../styles/canvas.module.css"
import obj1 from "../assets/objects/object-1.png"
import obj2 from "../assets/objects/object-2.png"
import obj3 from "../assets/objects/object-3.png"
import obj4 from "../assets/objects/object-4.png"
import obj5 from "../assets/objects/object-5.png"
import obj6 from "../assets/objects/object-6.png"
import obj7 from "../assets/objects/object-7.png"
import obj8 from "../assets/objects/object-8.png"
import obj9 from "../assets/objects/object-9.png"

export default function Canvas() {
    return (
        <div className={styles.canvas}>
        <Particles
    params={{
	    "particles": {
	        "number": {
	            "value": 12,
	            "density": {
	                "enable": false,
	                "value_area": 800
	            }
	        },
	        "line_linked": {
	            "enable": false
	        },
	        "move": {
	            "speed": 3,
	            "out_mode": "out"
	        },
	        "shape": {
	            "type": [
	                "image"
	            ],
	            "image": [
	                {
	                    "src": obj1
	                },
	                {
	                    "src": obj2
	                },
	                {
	                    "src": obj3
	                },
                    {
	                    "src": obj4
	                },
                    {
	                    "src": obj5
	                },
                    {
	                    "src": obj6
	                },
                    {
	                    "src": obj7
	                },
                    {
	                    "src": obj8
	                },
                    {
	                    "src": obj9
	                },
	            ]
	        },
	        "size": {
	            "value": 50,
	            "random": false,
	            "anim": {
	                "enable": true,
	                "speed": 10,
	                "size_min": 10,
	                "sync": false
	            }
	        },
            "opacity": {
                "value": 1,
                "random": false
            }
	    },
	    "retina_detect": false
	}} />
        </div>
    )
}